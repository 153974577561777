import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Layout = ({ children }) => {
  const { icon } = useStaticQuery(
    graphql`
      query {
        icon: file(relativePath: { eq: "s9gamesLOGO(alpha)2.png" }) {
          childImageSharp {
            fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  )

  return (
    <>
      <div>
        <main className="space-y-4">{children}</main>
        <footer>
          <div className="bg-bottompurple pb-2">
            <div className="max-w-7xl mx-auto py-1 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
              <div className="flex justify-center md:justify-end md:order-2 pt-3 w-full sm:w-1/3">
                <div className="flex flex-row">
                  <a href="https://www.studio9inc.com">
                    <Img className="w-16" fluid={icon.childImageSharp.fluid} />
                  </a>
                  <div className="mt-1 text-white self-center font-opensansSemiBold ">
                    <a href="https://www.studio9inc.com">Studio 9 Games</a>
                  </div>
                </div>
              </div>
              <div className="mt-4 text-white font-opensansSemiBold md:mt-3 md:order-1 flex flex-col justify-center text-center sm:justify-start sm:text-left">
                <p className="text-base leading-6">
                  &copy; {new Date().getFullYear()}
                </p>
                <p className="text-xs">
                  Built by{" "}
                  <a href="https://twitter.com/johnmcornyn">Cosmic Fisherman</a>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default Layout
